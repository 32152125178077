import { template as template_d25801e64c1d43d6b4a25c90c74b9eb7 } from "@ember/template-compiler";
const SidebarSectionMessage = template_d25801e64c1d43d6b4a25c90c74b9eb7(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
