import { template as template_8373b80048f342d293b84ef6adc2ad7e } from "@ember/template-compiler";
const FKText = template_8373b80048f342d293b84ef6adc2ad7e(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
