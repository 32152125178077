import { template as template_f32bbd168a7d42b5a38ae8d3e2f5d861 } from "@ember/template-compiler";
const FKControlMenuContainer = template_f32bbd168a7d42b5a38ae8d3e2f5d861(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
